<template>
  <form-layout sub-text="Sign in to your Tokoin account">
    <form @submit.prevent="onLogin">
      <div class="form-group">
        <label class="form-label">Email</label>
        <input
          type="email"
          :class="['form-control', { 'form-control--error': $v.params.email.$anyError }]"
          v-model="$v.params.email.$model"
        />
        <p v-if="$v.params.email.$anyError" class="form-error-text">
          <span v-if="!$v.params.email.required">Email can't be blank</span>
          <span v-if="!$v.params.email.email">Email is invalid</span>
        </p>
      </div>

      <div class="form-group">
        <label class="form-label">Password</label>
        <input
          type="password"
          autocomplete
          :class="['form-control', { 'form-control--error': $v.params.password.$anyError }]"
          v-model="$v.params.password.$model"
        />
        <p v-if="$v.params.password.$error" class="form-error-text">Password can't be blank</p>
      </div>

      <!-- TODO: implement forgotpassword -->
      <!-- <div class="mt-24 d-flex">
        <label class="form-checkbox">
          Remember me
          <input type="checkbox" name="gender" />
          <span></span>
        </label>

        <router-link
          class="link link--primary"
          to="/password/reset">
          Forgot Password?
        </router-link>
      </div> -->

      <div class="mt-24">
        <button class="btn btn--primary btn--full">Sign In</button>
      </div>
    </form>
  </form-layout>
</template>

<script>
import { mapActions } from 'vuex'
import loginFormValidator from './validations/login-form'
const FormLayout = () => import('@/layouts/FormLayout')

export default {
  data() {
    return {
      params: {
        email: '',
        password: ''
      }
    }
  },

  mixins: [loginFormValidator],

  components: {
    FormLayout
  },

  methods: {
    ...mapActions('auth', ['login']),

    onLogin() {
      this.$v.$touch()
      if (this.isFormValid) this.login(this.params)
    }
  }
}
</script>
